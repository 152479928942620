import * as React from "react"
import { Link } from "gatsby"

import Layout from "src/components/layout"
import Seo from "src/components/seo"

const FarmsPage = () => (
  <Layout>
    <Seo title="BLUME Farms" />
    <h1>BLUME Farms</h1>
    <p>Content coming soon.</p>
  </Layout>
)

export default FarmsPage
